$primaryColor: #40a9ff;

body{
  background: #FFF;
}
#main-menu.ant-menu {
  li {
    border: 0 none;
    margin: 0;
    a {
      &.active {
        color: $primaryColor;
      }
    }
  }
}

.ant-layout-header {
  box-shadow: 0 2px 8px #f0f1f2;
}

.page-title {
  margin-bottom: 20px;
}

.enabled-procedures {
  .ant-checkbox-group-item {
    display: block;
  }
}

@media (max-width: 768px) {
  .ant-layout {
    .ant-layout-sider {
      display: none;
    }
  }
}